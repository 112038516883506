import React, { useEffect } from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { RootState } from '../../configuration/setup/store';
import { useAppDispatch } from '../../configuration/setup/hooks';
import axios from 'axios';

// Events received from web-workshop
export const TOKEN_RENEWED = 'WEB_WORKSHOP/TOKEN_RENEWED';

// Events sent to parent frontend
export const CLOSE_BUTTON = 'DTCS/CLOSE_BUTTON';

interface MessageEvent<T> {
    type: string;
    payload: T;
}

export const sendMessage = (message: MessageEvent<any>) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

export const receiveMessage = (event: any, dispatch: ThunkDispatch<Dispatch<any>, RootState, any>) => {
    const actionType = event.data.type;
    if (!actionType) {
        // eslint-disable-next-line no-console
        console.log('Received event in dtcs without type: ', event);
        return;
    }
    switch (actionType) {
        case TOKEN_RENEWED:
            const { token } = event.data.payload;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            break;
        default:
            // eslint-disable-next-line no-console
            console.log('Received event in dtcs with type not handled: ', actionType);
            break;
    }
};

// eslint-disable-next-line react/display-name
export const MessageHandler = React.memo((props) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const listenerCallback = (event: any) => receiveMessage(event, dispatch);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
    }, []);

    return null;
});

export default MessageHandler;

import { isTraining, isWorkshopParentFrontend } from '../../config';

declare global {
    interface Window {
        dataLayer?: any[];
    }
}

export type GoogleAnalytics4Parameters = {
    element_name: string;
    component_name:
        | 'ApplicationLayout'
        | 'ApplicationHeader'
        | 'BottomSheet'
        | 'Sidebar'
        | 'MainNavigation'
        | 'SubNavigation'
        | 'AppNavigationBar'
        | 'Pager'
        | 'Button'
        | 'Checkbox'
        | 'Clearablelnput'
        | 'LoadMoreButton'
        | 'NumberControl'
        | 'RadioButton '
        | 'Slider'
        | 'Switch'
        | 'ToggleButton'
        | 'Collapse'
        | 'Expander'
        | 'Fade'
        | 'AssetTree'
        | 'AutoSuggest'
        | 'ButtonDropdown'
        | 'Select'
        | 'DatePicker'
        | 'FilePicker'
        | 'TimePicker'
        | 'StatusBar'
        | 'SteppedProgress '
        | 'AnimatedNumber'
        | 'Carousel'
        | 'ContentLoader'
        | 'DataTabs'
        | 'Dialog'
        | 'NoData'
        | 'ErrorState'
        | 'NotFoundState'
        | 'EmptyState'
        | 'ForbiddenState'
        | 'MaintenanceState'
        | 'CustomState'
        | 'NotBookedState'
        | 'StatsWidget'
        | 'TableToolbar'
        | 'TableSearch'
        | 'TableViewToggles'
        | 'TableCardsSorting'
        | 'TableSettingDialog'
        | 'Teaser'
        | 'Timeline'
        | 'AreaChart'
        | 'LineChart'
        | 'BarChart'
        | 'PieChart'
        | 'RadialBarChart'
        | 'Map'
        | 'SingleMapMarker'
        | 'ClusterMapMarker'
        | 'Marker';
    trigger: 'click' | 'visibility';
    flow_name?: string;
    element_state?: string;
    identification_type?: string;
    connection_type?: 'inbound' | 'outbound';
    connection_brand?: 'scania' | 'idem' | 'fleetboard' | 'volvo';
    components?: number;
    method?: string;
    search_term?: string;
    currency?: 'Eur' | 'Usd';
    value?: number;
    page_location?: string;
    page_title?: string;
    ecommerce?: object;
    asset_id?: string;
    rating?: number;
    callback_requested?: 'yes' | 'no';
};

export type GoogleAnalytics4UserProperties = {
    login_method?: 'mobile' | 'email';
    account_id?: string;
    tenant?: string; // rio-eu.test, rio-eu.prod, ...
    user_id?: string;
};

export interface GAEvent {
    event: GoogleAnalyticsCustomEvents;
    parameters: GoogleAnalytics4Parameters;
    userProps: GoogleAnalytics4UserProperties;
}

// Custom events are always in snake_case
export type GoogleAnalyticsCustomEvents = 'dtcs_test_event';

let rioUserTenant: string;

export const setRioUserTenant = (tenant: string) => {
    rioUserTenant = tenant;
};

export const gaPush = (gaEvent: GAEvent) => {
    if (import.meta.env.DEV) {
        console.info('[DEVELOPMENT] skip GA4 Event: ', gaEvent);
        return;
    }
    const { event, parameters, userProps } = gaEvent;
    const ignoreGaEvents = (isWorkshopParentFrontend() && isTraining()) || rioUserTenant === 'rio-eu.test';
    if (!ignoreGaEvents && window.dataLayer) {
        window.dataLayer.push({
            event,
            ...parameters,
            ...userProps,
        });
    }
};

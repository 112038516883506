import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../config';

// TODO To be removed/updated when a new project is created.
const useFetchExample = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [exampleText, setExampleText] = useState<string>('');
    const [reloadState, setReloadState] = useState<{}>({});
    const triggerReload = () => {
        setHasErrors(false);
        setReloadState({});
    };
    useEffect(() => {
        if (config.backend.BACKEND_URI) {
            axios
                .get(config.backend.BACKEND_URI + '/dtcs-examples')
                .then((res) => {
                    setExampleText(res.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching example endpoint', error);
                    setHasErrors(true);
                });
        }
    }, [reloadState]);
    return {
        isLoading,
        hasErrors,
        exampleText,
        triggerReload,
    };
};

export default useFetchExample;

export const TRAINING_PARAM = 'training';
export const PARENT_FRONTEND_PARAM = 'parent_frontend';
export const PARENT_FRONTEND_WORKSHOP = 'workshop';
export const PARENT_FRONTEND_CUSTOMER = 'customer';

export interface ConfigState {
    backend: {
        BACKEND_URI: string | undefined;
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
}

const getBoolEnvValue = (envValue: string): boolean => {
    return typeof import.meta.env[envValue] !== 'undefined' && import.meta.env[envValue] === 'true';
};

const getValueFromUrlParamOrLocalStorage = (paramName: string): string | undefined => {
    const currentUrl = new URL(window.location.href);
    const paramValue = currentUrl.searchParams.get(paramName);
    if (paramValue === null) {
        // if null we need to check the local storage
        const localStorageValue = window.localStorage.getItem(paramName);
        if (localStorageValue === null) {
            return undefined;
        }
        return localStorageValue;
    }
    return paramValue;
};

// This MicroFrontend can be integrated either on:
// - Customer Frontend (Rio Portal)
// - Workshop Frontend (Man Service Portal)
export const isWorkshopParentFrontend = (): boolean => {
    const paramValue = getValueFromUrlParamOrLocalStorage(PARENT_FRONTEND_PARAM);
    if (paramValue === undefined) {
        // by default start workshop-frontend
        return true;
    }
    return paramValue === PARENT_FRONTEND_WORKSHOP;
};

export const isTraining = (): boolean => {
    const paramValue = getValueFromUrlParamOrLocalStorage(TRAINING_PARAM);
    if (paramValue === undefined) {
        // by default set training to false
        return false;
    }
    return paramValue === 'true';
};

export const config: ConfigState = {
    backend: {
        BACKEND_URI: import.meta.env.VITE_BACKEND_URI,
        AUTHENTICATION_SERVICE: isWorkshopParentFrontend()
            ? import.meta.env.VITE_MAN_AUTHENTICATION_SERVICE
            : import.meta.env.VITE_RIO_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_RIO_MENU_SERVICE,
    },
    homeRoute: import.meta.env.VITE_RIO_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: isWorkshopParentFrontend()
            ? import.meta.env.VITE_MAN_LOGIN_AUTHORITY
            : import.meta.env.VITE_RIO_LOGIN_AUTHORITY,
        // TODO: Request and supply your App's `client_id` as
        //       well as the needed OAuth scopes here
        clientId: isWorkshopParentFrontend() ? import.meta.env.VITE_MAN_CLIENT_ID : import.meta.env.VITE_RIO_CLIENT_ID,
        oauthScope: isWorkshopParentFrontend()
            ? // note: offline_access is needed to activate refresh tokens by oidc-client
              ['email', 'openid', 'profile', 'offline_access', `${import.meta.env.VITE_MAN_CLIENT_ID}`]
            : ['email', 'openid', 'phone', 'profile', 'dtcs.read', 'dtcs.write'],
        mockAuthorization: getBoolEnvValue('VITE_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('VITE_LOGIN_PREVENT_REDIRECT'),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: isWorkshopParentFrontend() ? import.meta.env.VITE_MAN_LOGOUT_URI : import.meta.env.VITE_RIO_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'starterTemplate',
};
